/** Town: House Video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data;

class TownPage4 extends React.Component {

  constructor(props) {
    super(props);
    
    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[4];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);
    
    //If coming to this chapter by clicking prev
    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2, 75);  //book basetrack (loop)
    } else {
      globalState.baseAudioRef.updateVolume(2, 75);
    }
    
    //Preload assets from next page
    preloadImages([globalState.cdnUrl + data.nextTitle], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    gsap.killTweensOf(this.refs.text2Ref);

    //remove base track
    //if(globalState.transitionDirection === "next") {
    //  globalState.baseAudioRef.updateTrack(1, false);
    //}

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
      this.bgVideoRef.hideVideo(0);
      this.bgVideoLoopRef.playVideo();
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop, 0);
      showText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom, 0);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text2Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  

  render() {

    return (

      <>

        <link rel="prefetch" href="/pinepointers" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/pinepointers" prevLocation="/town/3"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Town" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "2vw", marginTop: "5vh" }} onClick={this.toggleTextDisplay} alt="Wandering around back, though, I was met with that incredibly specific Canadian shield backyard. Lichen-covered rock where there would be a garden in more reasonable geography. Stubby evergreens and assorted pokey shrubs. Great Slave Lake always just beyond." />
            </div>
            <div className="text text--right-bottom" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "2vw", marginBottom: "5vh" }} onClick={this.toggleTextDisplay} alt="Here, my memories are thick, bound to immutable objects: a cloak of rock acting variously as crater, cave, cliff drinking tea with my dad atop our ‘mountain’ the time my brother kicked me in the face while climbing it. His foot ‘slipped.’ I still have the scar to prove this." />
            </div>
          </div>

          {data.bgVideoLoop ? 
          <FullscreenVideo file={globalState.videoUrl + data.bgVideoLoop} 
          poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} muted={true} loop={true} ref={div => this.bgVideoLoopRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} />
          : ''
          }

          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={false} muted={true} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }


        </div>
        )}
      </>
    );
  }


};

export default TownPage4
